/* Cards */
._card {
    height: 140px ;
    max-width: 1200px ;
    margin-bottom: 20px ;
    flex-direction: row ;
    align-items: center ;
    justify-content: start ;
    border-width: 1px ;
    border-style: solid ;
    border-radius: 5px ;
    border-color: #c4c4c4 ;
    background-color: #f7f7f7 ;
    cursor: pointer ;
}

.card-image-container {
    height: 112px ;
    width: 100px ;
    margin: 14px ;
    border-width: 1px ;
    border-style: solid ;
    border-radius: 5px ;
    border-color: #c4c4c4 ;

}

.card-details-container {
    height: 100% ;
    padding-top: 20px ;
    align-items: start ;
    justify-content: start ;
}

.card-title-detail-container {
    height: 22px ;
    font-size: 12px ;
    font-weight: 600 ;
    line-height: 12px ;
    padding-top: 12px ;
    padding-left: 10px ;
    align-items: start ;
    color: #393939 ;
}

.card-detail-container {
    height: 22px ;
    font-size: 11px ;
    line-height: 11px ;
    padding-top: 11px ;
    padding-left: 10px ;
    align-items: start ;
    color: #393939 ;
}

.match-card {
    flex-direction: row ;
    cursor: default ;
}

.match-card-team-container {
    height: 100% ;
    width: 30% ;
}

.match-card-team-crest-container {
    height: 70% ;
}

.match-card-team-name-container {
    height: 30% ;
    color: #393939 ;
}

.match-card-info-container {
    height: 100% ;
    width: 40% ;
}

.match-card-middle-container {
    height: 60% ;
    flex-direction: row ;
}

.match-card-score {
    height: 100% ;
    width: 40% ;
    font-size: 44px ;
    font-weight: 500 ;
    color: #393939 ;
}

.match-card-score-divider {
    height: 100% ;
    width: 20% ;
    font-size: 44px ;
    font-weight: 600 ;
    color: #393939 ;
}

.match-card-kickoff {
    height: 100% ;
    font-size: 44px ;
    font-weight: 500 ;
    color: #393939 ;
}

.match-card-details-container {
    height: 20% ;
    flex-direction: row ;
}

.match-card-detail {
    height: 100% ;
    flex: 1 ;
    font-size: 12px ;
    color: #393939 ;
}

.venue-card-image {
    width: 70px ;
}


/* Content Selectors */
.content-selector-active {
    height: 30px ;
    flex: 1 ;
    margin-left: 5px ;
    margin-right: 5px ;
    margin-bottom: 10px ;
    border-width: 1px ;
    border-style: solid ;
    border-radius: 5px ;
    border-color: #393939 ;
    background-color: #393939 ;
    font-size: 12px ;
    color: #f7f7f7 ;
    cursor: pointer ;
}

.content-selector-inactive {
    height: 30px ;
    flex: 1 ;
    margin-left: 5px ;
    margin-right: 5px ;
    margin-bottom: 10px ;
    border-width: 1px ;
    border-style: solid ;
    border-radius: 5px ;
    border-color: #393939 ;
    background-color: #f7f7f7 ;
    font-size: 12px ;
    color: #393939 ;
    cursor: pointer ;
}

/* Team Crests */
.team-crest {
    height: 20px ;
    width: 20px ;
}

.team-crest-large {
    height: 66px ;
    width: 66px ;
}

/* Team Bars */
.team-bar {
    height: 30px ;
    flex-direction: row ;
    margin-bottom: 10px ;
    border-width: 1px ;
    border-style: solid ;
    background-color: #f7f7f7 ;
    border-color: #c4c4c4 ;
}

.team-bar-crest-container {
    height: 100% ;
    width: 60px ;
    align-items: end ;
    padding-right: 10px ;
}

.team-bar-name-container {
    height: 100% ;
    padding-left: 15px ;
    align-items: start ;
    font-size: 12px ;
    color: #393939 ;
}

/* League Tables */
/* .league-table-header {
    height: 30px ;
    flex-direction: row ;
    border-bottom-width: 1px ;
    border-style: solid ;
    border-color: #393939 ;
    font-size: 12px ;
    color: #393939 ;
} */

.league-table-header {
    height: 30px ;
    flex-direction: row ;
    background-color: #393939 ;
    font-size: 12px ;
    color: #f7f7f7 ;
}

.league-table-row {
    height: 30px ;
    flex-direction: row ;
    margin-top: 10px ;
    background-color: #f7f7f7 ;
    font-size: 12px ;
    color: #393939 ;
}

.league-table-position-container {
    height: 100% ;
    width: 12% ;
}

.league-table-team-container {
    height: 100% ;
    width: 28% ;
    flex-direction: row ;
    justify-content: start ;
}

.league-table-team-name-container {
    height: 100% ;
    width: 100% ;
    align-items: start ;
}

.league-table-team-crest-container {
    height: 100% ;
    width: 40px ;
    align-items: start ;
}

.league-table-played-container {
    height: 100% ;
    width: 12% ;
}

.league-table-won-container {
    height: 100% ;
    width: 12% ;
}

.league-table-drawn-container {
    height: 100% ;
    width: 12% ;
}

.league-table-lost-container {
    height: 100% ;
    width: 12% ;
}

.league-table-points-container {
    height: 100% ;
    width: 12% ;
}

/* Forms - form */
.form-container{
    margin-top: 30px ;
}

.form{
    width: 90% ;
    max-width: 600px ;
    margin-bottom: 20px ;
    justify-content: start ;
    border-width: 1px ;
    border-style: solid ;
    border-radius: 5px ;
    border-color: #c4c4c4 ;
    background-color: #f7f7f7 ;
}

.authform{
    max-width: 400px ;
    background-color: #ffffff ;
}

.form-header {
    height: 40px ;
}

.form-title-header {
    height: 90px ;
    justify-content: end ;
    padding-bottom: 20px ;
    font-size: 24px ;
    color: #393939 ;
}

.form-footer {
    height: 30px ;
}

.form-error-message-container {
    height: 20px ;
    justify-content: start ;
    padding-bottom: 0px ;
    font-size: 11px ;
    color: #ee4f24 ;
}

.form-button-container {
    height: 30px ;
}

/* Forms - fields */
.form-field-row-container {
    height: 40px ;
    flex-direction: row ;
    align-items: start ;
}

.form-field-label-container {
    min-height: 26px ;
    width: 44% ;
    align-items: end ;
    padding-right: 10px ;
    font-size: 11px ;
    color: #393939 ;
}

.form-field-container {
    height: 100% ;
    flex-direction: row ;
    justify-content: start ;
    align-items: start ;
}

._input {
    min-height: 26px ;
    width: 70% ;
    max-width: 300px ;
    padding-top: 1px ;
    padding-bottom: 1px ;
    padding-left: 10px ;
    border-width: 1px ;
    border-style: solid ;
    border-radius: 5px ;
    border-color: #c4c4c4 ;
    background-color: #ffffff ;
    font-size: 11px ;
    color: #393939 ;
    cursor: pointer ;
}

.picker {
    min-height: 26px ;
    width: 70% ;
    max-width: 300px ;
    padding-top: 1px ;
    padding-bottom: 1px ;
    border-width: 1px ;
    border-style: solid ;
    border-radius: 5px ;
    border-color: #c4c4c4 ;
    background-color: #ffffff ;
    font-size: 11px ;
    /* color: #393939 ; */
    cursor: pointer ;
}

.date-picker {
    width: 85px ;
    height: 25px ;
    padding-left: 5px ;
}

.time-picker {
    width: 25px ;
}

.time-picker-divider-div {
    width: 15px ;
}

.selected-picker {
    position: relative ;
    z-index: 1000 ;
}

.picker-value {
    padding-top: 2px ;
    padding-bottom: 2px ;
}

.confirmed-picker-value {
    font-size: 11px ;
    color: #393939 ;
}

.unconfirmed-picker-value {
    color: #c4c4c4 ;
}

.selected-picker-value {
    border-bottom-width: 1px ;
    border-style: solid ;
    border-radius: 5px ;
    border-color: #c4c4c4 
}

.picker-option {
    padding-top: 2px ;
    padding-bottom: 2px ;
}

.picker-options-container {
    max-height: 84px ;
    justify-content: start ;
    overflow-y: scroll ;
}

/*Forms - buttons */
._button {
    width: 65px ;
    height: 20px ;
    font-size: 11px ;
    border-width: 1 ;
    border-style: solid ;
    border-radius: 5px ;
}

._button:hover {
    cursor: pointer;
}

.enabled-button {
    border-color: #393939 ;
    background-color: #393939 ;
    color: #c4c4c4 ;
}

.disabled-button {
    border-color: #c4c4c4 ;
    background-color: #c4c4c4 ;
    color: #f7f7f7 ;
}

.auth-button {
    width: 150px ;
    height: 25px ;
    background-color: #0d6efd ;
    color: #ffffff ;
}