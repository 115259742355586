.authCard {
    min-height: 300px ;
    max-width: 400px ;
    margin: auto ;
    margin-top: 100px ;
}

.authInput {
    width: 100% ;
    margin-top: 10px;
}

.authSubmitButton {
    width: 100% ;
    margin-top: 25px ;
    margin-bottom: 20px ;
}

.authSwitchText {
    flex-direction: row ;
    width: 100% ;
    margin-top: 20px ;
    text-align: center ;
}