div {
    width: 100% ;
    padding: 0px ;
    margin: 0px ;
    border-width: 0px ;
    border-color: black ;
    border-style: solid ;
    display: flex ;
    flex-direction: column ;
    align-items: center ;
    justify-content: center ;
}

.mainContainer {
    justify-content: start ;
}

.page-title-container {
    height: 80px ;
    align-items: start ;
    justify-content: end ;
    padding-left: 20px ;
    padding-bottom: 10px ;
    font-size: 24px ;
    color: #393939 ;
}

.page-subtitle-container {
    height: 80px ;
    align-items: center ;
    justify-content: end ;
    padding-left: 30px ;
    padding-bottom: 10px ;
    font-size: 20px ;
    color: #393939 ;
}

.loading-container {
    height: 200px ;
}

.card-container {
    width: 80% ;
    max-width: 1200px ;
    padding-top: 50px ;
    padding-bottom: 50px ;
}

.no-results-container {
    font-size: 12px ;
}

.content-selector-container-container {
    height: 60px ;
}

.content-selector-container {
    height: 100% ;
    width: 80% ;
    max-width: 1200px ;
    flex-direction: row ;
    align-items: end ;
}

.selection-container {
    padding-bottom: 20px ;
    padding-left: 5px ;
    padding-right: 5px ;
    width: 80% ;
    max-width: 1200px ;
}

.edit-container {
    height: 30px ;
    flex-direction: row ;
    align-items: end ;
    justify-content: end ;
    padding-left: 5px ;
    padding-right: 5px ;
    margin-bottom: 10px ;
}

.edit-link-container {
    height: 100% ;
    width: 80px ;
    align-items: end ;
    justify-content: end ;
    font-size: 12px ;
    color: #393939 ;
}

.page-bottom-container {
    height: 140px ;
}

.edit-link {
    color: #393939 ;
}

.edit-link:hover {
    color: #393939 ;
}

/* Scrollbar - always show */
::-webkit-scrollbar {
    -webkit-appearance: none ;
    width: 7px ;
  }
  
  ::-webkit-scrollbar-thumb {
    border-radius: 4px ;
    background-color: rgba(0, 0, 0, .5) ;
    box-shadow: 0 0 1px rgba(255, 255, 255, .5) ;
  }