/* navbar */
.navbar {
    width: 100% ;
    height: 45px ;
    flex-direction: row ;
    align-items: center ;
    justify-content: start ;
    border-width: 2px ;
    border-color: black ;
    border-style: solid ;
    background-color: #000000 ;
}

.active-nav-link {
    color: #ee4f24 ;
    font-size: 14px ;
    font-weight: 600 ;
    text-decoration: none ;
}

.active-nav-link:hover {
    color: #ee4f24 ;
}

.inactive-nav-link {
    color: #c4c4c4 ;
    font-size: 14px ;
    font-weight: 400 ;
    text-decoration: none ;
}

.inactive-nav-link:hover {
    color: #c4c4c4 ;
}

.logout-button {
    width: 70px ;
    height: 30px ;
    align-items: center ;
    justify-content: center ;
    border-width: 0px ;
    border-style: solid ;
    border-radius: 5px ;
    border-color: #ffffff ;
    text-align: center ;
    font-size: 12px ;
    color: #c4c4c4 ;
    background-color: #393939 ;
    cursor: pointer ;
}

/* breadcrumbs */
.breadcrumb-container {
    height: 24px ;
    flex-direction: row ;
    align-items: end ;
    justify-content: start ;
    padding-left: 40px ;
    padding-bottom: 5px ;
}

.breadcrumbs {
    height: 20px ;
    padding-top: 14px ;
    flex-direction: row ;
    justify-content: start ;
}

.breadcrumb {
    font-size: 14px ;
    line-height: 14px ;
    color: #c4c4c4 ;
    text-decoration: underline ;
    text-align: start ;
}

.breadcrumb-divider {
    font-size: 12px ;
    color: #c4c4c4 ;
    text-decoration: none ;
    text-align: start ;
    padding-left: 5px ;
    padding-right: 5px ;
}